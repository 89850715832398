<template>
  <div id="app">
    <van-sticky class="top-nav">
      <img class="top-logo" src="./assets/images/logo.png" @click="handlePage(1)" />
      <div class="r-menu">
        <van-collapse v-model="activeNames" :border="false">
          <van-collapse-item name="1" :border="false" :is-link="false">
            <template #title>
              <div class="menu-icon">
                <img src="./assets/images/menu-icon.png" />
              </div>
            </template>
            <div class="menu-item" @click="handlePage(1)">首页</div>
            <div class="menu-item" @click="handlePage(2)">关于我们</div>
            <div class="menu-item" @click="handlePage(3)">APP 下载</div>
            <div class="menu-item" @click="handlePage(4)">联系我们</div>
          </van-collapse-item>
        </van-collapse>
      </div>
    </van-sticky>
    <router-view />
    <div class="h125"></div>
    <div class="footer">
      <div class="footer-content">
        <img class="bottom-logo" src="./assets/images/logo.png" />
        <div class="big-title">ទាក់ទងមកយើងខ្ញុំ  联系我们</div>
        <div class="footer-row">
          <img class="l-img" src="./assets/images/address.png" />
          <div class="r-txt">
            <div class="txt">Street 1960, Phum Poung Peay, Phnom Penh Thmei, Sen Sok, Phnom Penh, Cambodia</div>
          </div>
        </div>
        <div class="footer-row">
          <img class="l-img" src="./assets/images/email.png" />
          <div class="r-txt">
            <div class="txt">CS@doorsteptao.com</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Sticky, Collapse, CollapseItem } from 'vant';
import 'vant/lib/button/style';

export default {
  name: 'App',
  components: {
    [Sticky.name]: Sticky,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem
  },
  data() {
    return {
      activeNames: []
    }
  },
  methods: {
    handlePage (_type) {
      let _path = '';
      switch (_type) {
        case 1:
          _path = '/';
          break;
        case 2:
          _path = '/about';
          break;
        case 3:
          _path = '/download';
          break;
        case 4:
          _path = '/contact';
          break;
      }
      this.$router.push(_path);
      this.activeNames = [];
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.top-nav {
  position: relative;
  height: 48px;
  background: #13131D;
}
.top-logo {
  position: absolute;
  top: 10px;
  left: 11px;
  z-index: 9;
  width: 26px;
}
.r-menu {
  position: relative;
  z-index: 1;
}
.r-menu .van-cell,
.r-menu .van-collapse-item__content {
  background: #13131D;
}
.r-menu .van-cell {
  padding: 11px;
  height: 48px;
}
.van-collapse-item__content {
  padding-top: 0;
}
.menu-icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  float: right;
  width: 36px;
  height: 27px;
  line-height: 0;
  border-radius: 3px;
  box-sizing: border-box;
  background: rgba(255, 255, 255, .1);
}
.menu-icon img {
  width: 27px;
  height: 22px;
}
.menu-item {
  padding-top: 10px;
  padding-bottom: 9px;
  border-bottom: dashed 1px #B0B0B0;
  color: #B0B0B0;
}
.footer {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
}
.footer-content {
  position: relative;
  padding: 15px 12px;
  text-align: left;
  background: #13131D;
  color: #B0B0B0;
}
.bottom-logo {
  position: absolute;
  top: 23px;
  right: 20px;
  width: 26px;
}
.big-title {
  padding-bottom: 10px;
  line-height: 25px;
  color: #fff;
  font-size: 14px;
}
.footer-row {
  display: block;
  overflow: hidden;
  padding-right: 40px;
}
.footer-row .l-img {
  float: left;
  width: 16px;
}
.footer-row .r-txt {
  display: block;
  overflow: hidden;
  padding-left: 10px;
  padding-bottom: 3px;
  line-height: 18px;
  font-size: 12px;
}
.h125 {
  height: 125px;
}
</style>
